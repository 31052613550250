import './Host_Registration.css'
import {Host_Event_Name} from '../../../common/src/state/Event_And_State_Names'
import {Base_Props} from '../App/App'
import {useForm} from 'react-hook-form'
import {Standard_Page_Layout} from '../Components/Standard_Page_Layout'
import { Help_Tips, show_help_tip } from '../Help_Tips/Help_tips'
import { useEffect } from 'react'

type FormValues = {
	host_email: string
}

export function Host_Registration(props: Base_Props) {

	const {send} = props
	const { is_trial } = props.context
	const {
		register,
		handleSubmit,
		formState: {errors}
	} = useForm<FormValues>({
		defaultValues: {
		}
	})

	useEffect(() => {
		if (is_trial) {
			show_help_tip('intro')
		}
	}, [])
	
	const onSubmit = handleSubmit(data =>
		send({ type: Host_Event_Name.register_host, detail: { ...data, is_trial }}),
	)

	const header = (
		<>
			<div className="page_header">Create game</div>
			<div className="sub_header">lets get you started</div>
		</>
	)
	const content = (
		<>
			<div className="header_over_form">
				<div className="h2">Enter your email to start</div>
			</div>
			<form id="host_reg_form" onSubmit={onSubmit}>
				<input
					type="email"
					data-help-tip="intro"
					className={'big_input ' + (errors.host_email ? 'validation_warn' : '')}
					{...register('host_email', {
						required: {
							value: true,
							message: 'required for verification',
						},
						maxLength: {value: 100, message: 'too long'},
						minLength: {value: 6, message: 'too short'},
						pattern: {
							value:
								/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
							message: 'invalid email',
						},
					})}
					placeholder="enter your email"
				/>
				<div className="validation_error">
					{errors.host_email?.message}
				</div>
			</form>
			<Help_Tips id="intro">
				<div>
					<h2> First time? Welcome!</h2>
					<p>
						Lets take you through hosting a game so you can see how it works. You're going to be
						hosting a normal game except it also has these helpful tips to get you started.
					</p>
					<p>
						Enter your email here and we'll send you a verification code.
					</p>
					<p>
						you get two free games then after that you
						can buy a game for 30 cents. (this one doesn't count)
					</p>
				</div>
			</Help_Tips>
		</>
	)

	const controls = (
		<div className="two_buttons">
			<button className="negative" onClick={() => history.back()}>
				Cancel
			</button>
			<button type="submit" form="host_reg_form">
				Start setup
			</button>
		</div>
	)
	return (
		<Standard_Page_Layout
			header={header}
			content={content}
			controls={controls}
		/>
	)
}
