import './Player_in_game.css'
import {Base_Props} from '../App/App'
import {Client_Event_Name} from '../../../common/src/state/Event_And_State_Names'
import {Standard_Page_Layout} from '../Components/Standard_Page_Layout'

export function Player_In_Game(props: Base_Props) {
	const {
		send,
		context: {
			teams,
			players,
			teams_enabled,
			player_name,
			player_secret,
			whos_buzzer_is_active,
		},
	} = props

	const buzz = () =>
		send({
			type: Client_Event_Name.buzz,
			detail: {player_name, player_secret},
		})
	const buzzer_disabled =
		!!whos_buzzer_is_active && player_name !== whos_buzzer_is_active
	const buzzer_pressed = player_name === whos_buzzer_is_active

	const header = (
		<>
			<div className="page_header">Game on!</div>
			<div className="sub_header">
				player: {player_name}
			</div>
		</>
	)
	const content = (
		<>
			<div className="buzzer_container">
				<button
					data-testId="buzzer"
					className={`buzzer ${buzzer_pressed ? 'active' : ''}`}
					disabled={buzzer_disabled}
					onClick={buzz}></button>
			</div>
		</>
	)

	const controls = (
		<>
			{teams_enabled ? (
				<div className="scoreboard">
					{teams.list.map(team => (
						<>
							<div className="score_entry">
								<div className="score_header">{team.name}</div>
								<div className="score_value">{team.score}</div>
							</div>
						</>
					))}
				</div>
			) : (
				<div className="scoreboard">
					{players.list.map(player => (
						<>
							<div className="score_entry">
								<div className="score_header">{player.name}</div>
								<div className="score_value">{player.score}</div>
							</div>
						</>
					))}
				</div>
			)}
		</>
	)
	return (
		<Standard_Page_Layout
			header={header}
			content={content}
			controls={controls}
		/>
	)
}
