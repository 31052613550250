import { AnyEventObject } from "xstate"
import { PlayerContext } from "./player_machine"

interface Guard_Params {
    context: PlayerContext
    event: AnyEventObject
}

export const all_players_are_in_teams = (params: Guard_Params) => {
    const { context, event } = params
    if (!context.teams_enabled) {
        return true
    }
    // generate a map of which teams players are in:
    const player_teams: {[index: string]: string} = {}
    context.teams.list.map(team =>
        team.members.list.map(player => (player_teams[player.name] = team.name)),
    )
    // Find any players who aren't in a team:
    if (context.players.list.some(player => !player_teams[player.name])) {
        event.detail = event.detail || {}
        event.detail.error_message = 'All players must be in teams before you can start the game'
        return false
    }
    return true
}