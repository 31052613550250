import './Host_In_Game.css'
import {
	Client_Event_Name,
	Host_Event_Name,
} from '../../../common/src/state/Event_And_State_Names'
import {Base_Props} from '../App/App'
import {Player, Team} from '../../../common/src/models/Base_Concepts'
import {Standard_Page_Layout} from '../Components/Standard_Page_Layout'
import { CUSTOM_QUESTIONS, Subject_Listing } from '../../../common/src/models/Trivia'
import { Help_Tips, show_help_tip } from '../Help_Tips/Help_tips'
import { useEffect } from 'react'

export function Host_In_Game(props: Base_Props) {
	const {
		send,
		context: {
			teams,
			players,
			teams_enabled,
			whos_buzzer_is_active,
			trivia_topics,
			chosen_trivia_topic,
			round_num,
			question_num,
			question_text,
			answer_text,
			is_trial,
		},
	} = props

	useEffect(() => {
		if (is_trial) {
			show_help_tip('game_start')
		}
	}, [])

	const get_trivia_topics = () => send({ type: Host_Event_Name.get_trivia_topics})
	const use_my_own_questions = () => send({ type: Host_Event_Name.choose_trivia_topic, detail: { subject: {field: CUSTOM_QUESTIONS, name: CUSTOM_QUESTIONS} }})
	const choose_topic = (subj: Subject_Listing) => send({ type: Host_Event_Name.choose_trivia_topic, detail: { subject: subj }})
	const clear_buzz = () => send({type: Client_Event_Name.clear_buzz})
	const skip_question = () => send({type: Host_Event_Name.skip_question})
	const finish_round = () => send({type: Host_Event_Name.finish_round})
	const finish_game = () => send({type: Host_Event_Name.finish_game})
	const return_to_setup = () => send({type: Host_Event_Name.return_to_setup})
	const modify_team_score = (team: Team, diff: number) =>
		send({
			type: Host_Event_Name.change_team_score,
			detail: {
				team_name: team.name,
				score_diff: team.score + diff,
			},
		})
	const modify_player_score = (player: Player, diff: number) =>
		send({
			type: Host_Event_Name.change_player_score,
			detail: {
				player_name: player.name,
				score_diff: player.score + diff,
			},
		})

	const send_correct = () => send({type: Host_Event_Name.answer_is_correct, detail: { score_diff: 10 }})
	const send_incorrect = () => send({type: Host_Event_Name.answer_is_incorrect, detail: { score_diff: -5 }})

	const generate_trivia_topic_list = () => {
		if (trivia_topics?.fields.length) {
			return <>
				{trivia_topics.fields.map(field => (
				<div className="trivia_field_listing">
					<div className="trivia_field_name">{field.name}</div>
					<ul>
						{field.subjects.map(subj => <li onClick={() => choose_topic(subj)}>{subj.name}</li>)}
					</ul>
				</div>
			))}
			</>
		}
		else {
			return <div className="round_number_banner">
				<div className="round_number_header">Round {round_num}!</div>
			</div>
		}
	}

	const get_topic_name_display = () => {
		if (!chosen_trivia_topic) {
			return ''
		}
		else if (chosen_trivia_topic.field === CUSTOM_QUESTIONS) {
			return 'Custom round'
		}
		return `${chosen_trivia_topic.field} - ${chosen_trivia_topic.name}`
	}

	const buzzer_active = !!whos_buzzer_is_active

	const header = (
		<>
			<div className="page_header">{!chosen_trivia_topic ? 'Choose a topic' : 'Game on!'}</div>
			<div className="sub_header">Round: {round_num}</div>
		</>
	)

	const content = (
		<>
			{!chosen_trivia_topic ?
			<>
				<div className='two_buttons' data-help-tip='game_start'>
					<button className="choose_topic" onClick={get_trivia_topics}>Choose topic</button>
					<button className="choose_topic" onClick={use_my_own_questions}>Use my own</button>
				</div>
				<div>
					{generate_trivia_topic_list()}
				</div>
			</>
			:
			<>
				<div className="buzzed_section">
					<button
						disabled={!buzzer_active}
						className="negative"
						onClick={send_incorrect}>
						Incorrect
					</button>

					<div className="who_buzzed_frame">
						<div className="buzzed_label">
							<span>Buzzed:</span>
						</div>
						<div
							data-testId="who_buzzed"
							className={`who_buzzed ${whos_buzzer_is_active ? 'active' : ''}`}>
							{whos_buzzer_is_active ? whos_buzzer_is_active : ''}
						</div>
					</div>
					<button disabled={!buzzer_active} onClick={send_correct}>
						Correct
					</button>
				</div>
				<div className='question_display'>
					<div className="chosen_trivia_topic">Round {round_num}, Topic: {get_topic_name_display()}</div>
					<div className="question_header">Question {question_num}: {question_text}</div>
					<div className="answer_header">Answer {question_num}: {answer_text} <button className="inline tiny" onClick={skip_question}>Skip it</button></div>
				</div>
				{teams.list.map((team, i) => (
					<div data-testId={`team row ${team.name}`} className="team_container">
						<div className="team_left_side">
							<div className="host_team_name">{team.name}</div>
							<div className="scores_box">
								<div className="score_buttons">
									<div>
										<button
											data-testId={`team_score_plus_1_${i}`}
											onClick={() => modify_team_score(team, 1)}>
											+1
										</button>
										<button
											data-testId={`team_score_minus_1_${i}`}
											onClick={() => modify_team_score(team, 5)}>
											+5
										</button>
									</div>
									<div>
										<button
											data-testId={`team_score_plus_5_${i}`}
											className="negative"
											onClick={() => modify_team_score(team, -1)}>
											-1
										</button>
										<button
											data-testId={`team_score_minus_5_${i}`}
											className="negative"
											onClick={() => modify_team_score(team, -5)}>
											-5
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="team_center">
							<div>Score:</div>
							<div data-testId={`team_score_${i}`} className="score_box">
								{team.score}
							</div>
						</div>
						<div className="team_right_side">
							<div>Team members:</div>
							<div className="team_players_box">
								{team.members.list.map(member => (
									<div
										className={`player_item ${
											whos_buzzer_is_active === member.name ? 'buzzed' : ''
										}`}>
										{member.name}
									</div>
								))}
							</div>
						</div>
					</div>
				))}

				{!teams_enabled && (
					<>
						<table className="host_player_list">
							<thead>
								<tr>
									<th>Player</th>
									<th>Score</th>
									<th>Connected</th>
									<th>+/-</th>
								</tr>
							</thead>
							{players.list.length === 0 && (
								<tr>
									<td colSpan={3}></td>
								</tr>
							)}
							<tbody>
								{players.list.map((player, i) => (
									<tr>
										<td>
											<div className="player_item" data-player-item={player.name}>
												{player.name}
											</div>
										</td>
										<td
											data-testId={`player_score_${i}`}
											className="player_score">
											{player.score}
										</td>
										<td>
											<div
												data-testId={`player_connected_${i}`}
												className={
													player.connected
														? 'socket_connected'
														: 'socket_disconnected'
												}></div>
										</td>
										<td>
											<button
												data-testId={`player_neg_button_${i}`}
												className="negative"
												onClick={() => modify_player_score(player, -5)}>
												-5
											</button>
											<button
												data-testId={`player_pos_button_${i}`}
												onClick={() => modify_player_score(player, +5)}>
												+5
											</button>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</>
				)}
			</>
			}
		</>
	)

	const controls = (
		<>
			<div className="two_buttons">
				
				
			</div>
			<div className="two_buttons">
				<button onClick={clear_buzz}>Clear buzzers</button>
				<button onClick={finish_round}>Finish Round</button>
			</div>
			<div className='two_buttons'>
				<button className="alternative" onClick={return_to_setup}>
					Pause Game
				</button>
				<button className="negative" onClick={finish_game}>
					Finish game
				</button>	
			</div>
			<Help_Tips id="game_start">
				<div>
					<h2>Before each round: pick a topic</h2>
					<p>
						At the start of each round you can choose a trivia topic from our list or
						you can tap 'Use my own' and the game will just keep track of the questions
						and the scores.
					</p>
					<p>
						Once you choose, you'll see the questions, players and teams.
					</p>
					
				</div>
			</Help_Tips>
		</>
	)

	return (
		<Standard_Page_Layout
			header={header}
			content={content}
			controls={controls}
		/>
	)
}
