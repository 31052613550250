/*
 * These types don't contain the actual questions, they're just here to represent
 * listings of fields and subjects that the host can choose from.
 * 
 * It goes Field -> Topic -> Questions
 * like:
 * Modern Art -> Some Famous Painter's name -> What's his most famous painting?
 * 
 */
export interface Field_Listing {
    name: string
    subjects: Subject_Listing[]
}

export interface Subject_Listing {
    field: string
    name: string
}

export interface Trivia_Fields {
    fields: Field_Listing[]
}

export const CUSTOM_QUESTIONS = 'CUSTOM_QUESTIONS'