import './Host_Ran_Out_Of_Games.css'
import {Client_Event_Name} from '../../../common/src/state/Event_And_State_Names'
import {Base_Props} from '../App/App'
import {Standard_Page_Layout} from '../Components/Standard_Page_Layout'
import {PayPalScriptProvider, PayPalButtons} from '@paypal/react-paypal-js'
import {get_environment} from '../Config/Client_Environments'
import {ChangeEvent, useRef, useState} from 'react'

// This doesn't seem to be exported properly by the paypal lib, so copy pasting it here
type OnApproveData = {
	billingToken?: string | null
	facilitatorAccessToken?: string
	orderID: string
	payerID?: string | null
	paymentID?: string | null
	subscriptionID?: string | null
	authCode?: string | null
}

async function create_order(
	quantity: number,
	unit_price_in_cents: number,
	host_email: string,
	host_secret: string,
) {
	return fetch('/api/v1/paypal/create-order', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		// use the "body" param to optionally pass additional order information
		// like product ids and quantities
		body: JSON.stringify({
			cart: [
				{
					sku: '1',
					unit_price_in_cents,
					quantity,
				},
			],
			host_email,
			host_secret,
		}),
	})
		.then(response => response.json())
		.then(order => {
			// Your code here after create the order
			return order.id
		})
}

export function Host_Ran_Out_Of_Games(props: Base_Props) {
	const {send} = props

	const send_cancel_game = () => send({type: Client_Event_Name.cancel_game})

	const unit_price_in_cents = 30

	const [quantity, set_quantity] = useState(0)

	const numRef = useRef<number>() // will be same object each render

	const format_into_currency_string = (cost_in_cents: number) => {
		const num_string = (cost_in_cents / 100).toFixed(2)
		return '$' + num_string
	}

	console.log('render with quantity: ', quantity)

	const change_quantity = (event: ChangeEvent) => {
		const q = parseInt((event.target as HTMLInputElement).value)
		if (!isNaN(q) && q > 0 && q < 500) {
			numRef.current = q // assign new num value each render
			set_quantity(q)
		}
	}

	function on_approve(data: OnApproveData) {
		// replace this url with your server
		return fetch('/api/v1/paypal/capture-order', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				orderID: data.orderID,
				host_email: props.context.host_email,
				host_secret: props.context.host_secret,
				game_code: props.context.game_code
			}),
		})
			.then(response => response.json())
			.then(orderData => {
				console.log('order captured', orderData)
			})
	}

	const header = (
		<>
			<div className="page_header">Uh Oh, you ran out</div>
			<div className="sub_header">buy some, quick, they're cheap!</div>
		</>
	)
	const content = (
		<>
			<div>Games cost 30 cents each</div>
			<input placeholder="quantity" onChange={change_quantity} />
			<div>
				Cost: {format_into_currency_string(quantity * unit_price_in_cents)}
			</div>
			<PayPalScriptProvider
				options={{
					clientId: get_environment().paypal_client_id,
					currency: 'AUD',
				}}>
				<PayPalButtons
					style={{layout: 'horizontal', tagline: false}}
					createOrder={() =>
						create_order(
							numRef.current || 1,
							unit_price_in_cents,
							props.context.host_email,
							props.context.host_secret,
						)
					}
					onApprove={on_approve}
				/>
			</PayPalScriptProvider>
		</>
	)

	const controls = (
		<>
			<button className="negative" onClick={send_cancel_game}>
				Cancel game
			</button>
		</>
	)
	return (
		<Standard_Page_Layout
			header={header}
			content={content}
			controls={controls}
		/>
	)
}
