
interface Help_Tip_Props {
	id: string
	children: JSX.Element,
	button_text?: string,
	chain_next_tip?: string,
	button_action?: () => void
}

export function Help_Tips(props: Help_Tip_Props) {

	const dismiss = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		dismiss_help_tip(event, props.id)
		if (props.button_action) {
			try {
				props.button_action()
			}
			catch(e) {
				console.error(`error running button action for help tip ${props.id}`, e)
			}
		}
		if (props.chain_next_tip) {
			show_help_tip(props.chain_next_tip)
		}
	}

	return (
		<div data-help-tip-cover={props.id} className="help-tip-modal-cover">
			<div data-help-tip-content={props.id} className="help-tip">
				{props.children}
				<button onClick={(event) => dismiss(event)}>{props.button_text ? props.button_text : "Got it"}</button>
			</div>
		</div>
	)
}

function get_elements_for_help_tip(tip_id: string) {
	return {
		base: document.querySelector(`[data-help-tips-base="base"]`),
		cover: document.querySelector(`[data-help-tip-cover="${tip_id}"]`) as HTMLDivElement,
		next_to: document.querySelector(`[data-help-tip="${tip_id}"]`) as HTMLDivElement,
		content: document.querySelector(`[data-help-tip-content="${tip_id}"]`) as HTMLDivElement,
	}
}

export const show_help_tip = (tip_id: string) => {
	// Runs this async to make sure rendering has finished before positioning the help tip:
	setTimeout(() => {
		const elems = get_elements_for_help_tip(tip_id)
		if (!elems.base || !elems.next_to || !elems.content || !elems.cover) {
			throw new Error(`help tip not set up correctly - ${tip_id}`)
		}
		elems.cover.style.display = 'block';
		const bounds = elems.next_to.getBoundingClientRect()
		
		elems.content.style.top = bounds.top + bounds.height + 'px'
		elems.content.style.left = bounds?.left + 'px'
	}, 1)
}

const dismiss_help_tip = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, tip_id: string) => {
	const cover = document.querySelector(`[data-help-tip-cover="${tip_id}"]`) as HTMLDivElement
	if (cover) cover.style.display = 'none';
	event.preventDefault()
	return false
}
