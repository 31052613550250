import './Splash_Page.css'
import {Base_Props} from '../App/App'
import {Standard_Page_Layout} from '../Components/Standard_Page_Layout'
import button_icon_1st from '../../assets/button_icon_1st.svg'
import button_icon_host from '../../assets/button_icon_host.svg'
import button_icon_resume from '../../assets/button_icon_resume.svg'
import button_icon_help from '../../assets/button_icon_help.svg'

export function Splash_Page(props: Base_Props) {

	const header = (
		<>
			<div className="page_header">Quiz Buzz</div>
			<div className="sub_header">Game show buzzers for everyone</div>
		</>
	)

	const content = (
		<div className="vertical">

			<div
				className="button_container_area"
				onClick={() => props.send({type: 'start_host_trial_registration'})}>
					<img src={button_icon_1st}></img><h1>First time host</h1>
			</div>

			<div
				className="button_container_area"
				onClick={() => props.send({type: 'start_host_registration'})}>
					<img src={button_icon_host}></img><h1>Host a game</h1>
			</div>

			<div
				className="button_container_area"
				onClick={() => props.send({type: 'start_host_registration'})}>
					<img src={button_icon_resume}></img><h1>Resume game</h1>
			</div>

			<a href="/about/">
				<div
					id="host_button"
					className="button_container_area"
					onClick={() => props.send({type: 'start_host_registration'})}>
						<img src={button_icon_help}></img><h1>Info and help</h1>
				</div>
			</a>
		</div>
	)

	const controls = <></>

	return (
		<Standard_Page_Layout
			header={header}
			content={content}
			controls={controls}
		/>
	)
}
